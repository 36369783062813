




















































































import BaseDateRangePicker from '@/components/BaseDateRangePicker.vue';
import ConfirmDelete from '@/components/inventory/modals/ConfirmDelete.vue';
import CreateStockIn from '@/components/inventory/modals/CreateStockIn.vue';
import ImportStockInModal from '@/components/inventory/modals/ImportStockIn.vue';
import StockInDataTable from '@/components/inventory/StockInDataTable.vue';
import { apiManagement } from '@/services/apis/apiManagement';
import { formatNumber, formatPrice } from '@/utils/format';
import { Vue } from 'vue-property-decorator';

export default Vue.extend({
  components: {ConfirmDelete, BaseDateRangePicker, CreateStockIn, StockInDataTable, ImportStockInModal},
  data() {
    return {
      actionGetList: 0,
      searchQuery: '',
      rangeStockInTime: {
        start: null,
        end: null
      },
      totalData: {
        totalValue: 0
      },
      actionShowModalCreate: 0,
      actionShowModalDelete: 0,
      actionShowModalImport: 0,
      allSkuOptions: [],
      itemEdit: null,
      inventoryFilterIds: []
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    formatPrice,
    formatNumber,
    initialize() {
      this.getListSkuOption();
    },

    async getListSkuOption() {
      try {
        const response = await apiManagement.getAllSkuOptions();
        this.allSkuOptions = response.data;
      } catch (e) {
        console.log(e);
      }
    },

    handleExport() {
      this.$refs.stockInTable.exportExcel();
    },
    handleImport() {
      this.actionShowModalImport++;
    },
    handleAddNew() {
      this.itemEdit = null;
      this.actionShowModalCreate++;
    },
    handleShowEdit(item) {
      this.itemEdit = item;
      this.actionShowModalCreate++;
    },
    handleDeleteItem(item) {
      this.itemEdit = item;
      this.actionShowModalDelete++;
    },
    handleEnterSearch() {
      this.actionGetList++;
    },
    updateFilterStockinTime(value) {
      this.rangeStockInTime = {...value};
      this.actionGetList++;
    },
    changeFilterSKU() {
      this.actionGetList++;
    }
  },
});
