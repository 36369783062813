var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 custom-data-table",attrs:{"headers":_vm.headers,"footer-props":{'items-per-page-options':[10, 20, 50, 100, 200, 500]},"items-per-page":_vm.queryData.limit,"items":_vm.listData.data,"server-items-length":_vm.listData.count,"sort-by":"receivedAt"},on:{"update:sort-by":_vm.onSortByChange,"update:sort-desc":_vm.onSortDescChange,"update:page":_vm.changePage,"update:items-per-page":_vm.changePerPage},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex flex-wrap justify-center justify-md-space-between gap-4 px-4 py-4 border-b data-table-top"},[_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('h5',{staticClass:"text-h6"},[_vm._v("List Items")])]),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-btn',{staticClass:"text-button ml-3",attrs:{"color":"default"},on:{"click":_vm.handleViewHistory}},[_vm._v("View History")])],1)])]},proxy:true},{key:"item.unitPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPrice(item.unitPrice))+" ")]}},{key:"item.totalValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPrice(item.totalValue))+" ")]}},{key:"item.receivedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateFromBE(item.receivedAt))+" ")]}},{key:"item.createdTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateFromBE(item.createdTime))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),(item.status === 'Available')?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }