<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="modal-title">
          Import Excel
        </v-card-title>

        <v-card-text>
          <v-form
            ref="form"
            lazy-validation
          >
            <v-file-input
              v-model="file"
              accept=".xlsx, .xls"
              label="File input"
              :rules="rules.file"
              required
            ></v-file-input>
          </v-form>
        </v-card-text>

        <v-card-actions class="flex justify-end">
          <v-btn class="mr-4" @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="primary" @click="submit">
            Complete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogErrorImports"
      width="800"
    >
      <v-card>
        <v-card-title class="modal-title">
          Import Failed
        </v-card-title>

        <v-card-text>
          <v-simple-table>
            <thead>
            <tr>
              <th class="text-left">Number</th>
              <th class="text-left">SKU</th>
              <th class="text-left">Failed reason</th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="(item, index) in errorsImport"
              :key="index"
            >
              <td>{{ item.rowNumber }}</td>
              <td>{{ item.sku }}</td>
              <td>{{ item.reasons }}</td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>

        <v-card-actions class="flex justify-end">
          <v-btn class="mr-4" @click="dialogErrorImports = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import coreApiClient from '@/services/apis/coreApiClient'
import { apiManagement } from '@/services/apis/apiManagement'

export default {
  name: 'ImportStockInModal',
  data() {
    return {
      dialog: false,
      dialogErrorImports: false,
      errorsImport: [],
      file: null,
      rules: {
        file: [
          v => !!v || 'File is required'
        ]
      }
    }
  },

  props: {
    actionShowModal: { type: Number }
  },

  watch: {
    actionShowModal() {
      this.dialog = true
    },
    dialog(value) {
      if (!value) {
        this.dialogErrorImports = false
        this.$refs.form.reset()
      }
    }
  },

  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          await apiManagement.importStockIn({ file: this.file })

          this.$store.commit('setSuccessMessage', 'Import stock in successful')
          this.dialog = false
          this.$emit('success')
        } catch (e) {
          console.log(e)
          if (e?.response?.status === 422) {
            this.errorsImport = e.response.data.data.map(item => {
              return {
                rowNumber: item.rowNumber,
                sku: item.sku,
                reasons: item.messages.join(', ')
              }
            })
            this.dialogErrorImports = true
          } else {
            this.$store.commit('setErrorMessage', 'Import stock has error')
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-title {
  background-color: #006064;
  color: #fff;
  font-size: 20px
}
</style>
