<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card class="dialog-form">
        <v-card-title>Add New Stock In</v-card-title>
        <v-btn class="close-button" @click="dialog = false">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                  fill="#1D1B20" />
          </svg>
        </v-btn>
        <form>
          <v-autocomplete
              v-model="inventoryId"
              :items="allSkuOptions"
              :error-messages="inventoryIdErrors"
              item-text="sku"
              item-value="inventoryId"
              label="SKU"
              required
              :disabled="!!itemEdit"
              @change="$v.inventoryId.$touch()"
              @blur="$v.inventoryId.$touch()"
          ></v-autocomplete>
          <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="datetime"
              transition="scale-transition"
              offset-y
              min-width="auto"
              :disabled="!!itemEdit"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="datetime"
                  :error-messages="datetimeErrors"
                  label="Stock In Time"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="datetime"
                no-title
                scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                  text
                  color="primary"
                  @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(datetime)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-text-field
              v-model="quantityIn"
              :error-messages="quantityErrors"
              label="Quantity In"
              type="number"
              required
              @input="$v.quantityIn.$touch()"
              @blur="$v.quantityIn.$touch()"
              :disabled="itemEdit && itemEdit.status !== 'Available'"
          ></v-text-field>
          <v-text-field
              v-model="priceIn"
              :error-messages="priceErrors"
              label="Price In"
              type="number"
              required
              @input="$v.priceIn.$touch()"
              @blur="$v.priceIn.$touch()"
              :disabled="itemEdit && itemEdit.status !== 'Available'"
          ></v-text-field>
          <v-text-field
              v-model="name"
              :error-messages="nameErrors"
              label="Supplier"
              required
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
          ></v-text-field>
          <v-text-field
              v-model="description"
              label="Description"
              required
              @input="$v.description.$touch()"
              @blur="$v.description.$touch()"
          ></v-text-field>
          <v-card-actions class="flex">
            <v-btn class="mr-4" @click="dialog = false">
              Cancel
            </v-btn>
            <v-btn color="primary" @click="submit">
              Complete
            </v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { apiManagement } from '@/services/apis/apiManagement'
import { convertToTimestamp, getTimeFromTimestamp } from '@/utils/format'
import moment from 'moment'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CreateStockIn',
  mixins: [validationMixin],
  validations: {
    name: { required },
    description: {},
    inventoryId: { required },
    quantityIn: { required },
    priceIn: { required },
    datetime: { required },
  },

  data: () => ({
    name: '',
    description: '',
    inventoryId: null,
    quantityIn: null,
    priceIn: null,
    datetime: moment().format('YYYY-MM-DD'),
    menu: false,
    dialog: false,
  }),

  props: {
    actionShowModal: { type: Number, default: 0 },
    allSkuOptions: { type: Array },
    itemEdit: { type: Object, default: null }
  },

  watch: {
    actionShowModal() {
      this.dialog = true
      if (this.itemEdit) {
        this.inventoryId = this.itemEdit.inventoryId
        this.datetime = getTimeFromTimestamp(this.itemEdit.receivedAt, 'YYYY-MM-DD')
        this.name = this.itemEdit.supplierName
        this.description = this.itemEdit.description
        this.quantityIn = this.itemEdit.quantity
        this.priceIn = this.itemEdit.unitPrice
      }
    },
    dialog(newVal) {
      if (!newVal) {
        this.clear()
      }
    }
  },

  computed: {
    inventoryIdErrors() {
      const errors = []
      if (!this.$v.inventoryId.$dirty) {
        return errors
      }
      !this.$v.inventoryId.required && errors.push('Item is required')
      return errors
    },
    nameErrors() {
      const errors = []
      if (!this.$v.name.$dirty) {
        return errors
      }
      !this.$v.name.required && errors.push('Supplier name is required.')
      return errors
    },
    quantityErrors() {
      const errors = []
      if (!this.$v.quantityIn.$dirty) {
        return errors
      }
      !this.$v.quantityIn.required && errors.push('Quantity In is required')
      return errors
    },
    priceErrors() {
      const errors = []
      if (!this.$v.priceIn.$dirty) {
        return errors
      }
      !this.$v.priceIn.required && errors.push('Price In is required')
      return errors
    },
    datetimeErrors() {
      const errors = []
      if (!this.$v.datetime.$dirty) {
        return errors
      }
      !this.$v.datetime.required && errors.push('Datetime is required')
      return errors
    },
  },

  methods: {
    async submit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const formData = {
          inventoryId: this.inventoryId,
          receivedAt: convertToTimestamp(this.datetime, 'YYYY-MM-DD'),
          unitPrice: this.priceIn,
          quantity: this.quantityIn,
          supplierName: this.name,
          description: this.description
        }

        if (this.itemEdit) {
          delete formData.data.inventoryId
          delete formData.data.receivedAt
        }
        try {
          this.itemEdit ? await apiManagement.updateStockIn(this.itemEdit._id, { data: formData }) : await apiManagement.createStockIn(formData)
          this.$store.commit('setSuccessMessage', 'Save stock in successful')
          this.dialog = false
          this.$emit('success')
        } catch (e) {
          if (e?.response?.status === 400) {
            this.$store.commit('setErrorMessage', e.response.data.message)
          } else {
            this.$store.commit('setErrorMessage', 'Save stock in has error')
          }
        }
      }
    },
    clear() {
      this.$v.$reset()
      this.name = ''
      this.description = ''
      this.inventoryId = null
      this.quantityIn = null
      this.priceIn = null
      this.datetime = moment().format('YYYY-MM-DD')
    },
  },
}

</script>

<style lang="scss" scoped>
.dialog-form {
  padding: 34px 31px;
}

.v-card__title {
  padding: 0 !important;
  margin-bottom: 32px;
}

.close-button {
  position: absolute;
  top: 26px;
  right: 32px;
  background-color: transparent !important;
  border: none;
  box-shadow: none;
  padding: 0 !important;
  height: unset !important;
  min-width: unset !important;
  border-radius: 0;
}

::v-deep .v-input__prepend-outer {
  display: none !important;
}

.v-card__actions {
  justify-content: flex-end;
  padding-bottom: 0;
}
</style>
