<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card class="dialog-wrapper">
        <v-btn class="close-button" @click="dialog = false">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                  fill="#1D1B20" />
          </svg>
        </v-btn>
        <v-card-title>You sure want to delete this list?</v-card-title>
        <v-card-actions class="flex">
          <v-btn @click="dialog = false">
            No
          </v-btn>
          <v-btn class="btn-error" @click="submit">
            Yes, Delete List
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { apiManagement } from '@/services/apis/apiManagement'

export default {
  name: 'ConfirmDelete',
  data: () => ({
    dialog: false
  }),
  props: {
    actionShowModal: { type: Number, default: 0 },
    itemEdit: { type: Object, default: null }
  },

  watch: {
    actionShowModal() {
      this.dialog = true
    },
  },

  methods: {
    async submit() {
      const formData = { ids: [this.itemEdit._id] }

      try {
        await apiManagement.deleteStockIn(formData)
        this.$store.commit('setSuccessMessage', 'Delete stock in successful')
        this.dialog = false
        this.$emit('success')
      } catch (e) {
        if (e?.response?.status === 400) {
          this.$store.commit('setErrorMessage', e.response.data.message)
        } else {
          this.$store.commit('setErrorMessage', 'Delete stock in has error')
        }
      }
    }
  },
}

</script>

<style lang="scss" scoped>
.dialog-wrapper {
  padding: 50px 70px;
}

.v-card__title {
  padding: 20px 0 0 !important;
  margin-bottom: 75px;
  text-align: center;
  justify-content: center;
}

.close-button {
  position: absolute;
  top: 26px;
  right: 32px;
  background-color: transparent !important;
  border: none;
  box-shadow: none;
  padding: 0 !important;
  height: unset !important;
  min-width: unset !important;
  border-radius: 0;
}

.v-card__actions {
  gap: 16px;
  padding: 0 !important;
}

.v-card__actions button {
  width: calc(50% - 8px);
  margin: 0 !important;
}

.v-card__actions button.btn-error {
  background: #C30000 !important;
  color: white !important;
}
</style>
